import {getCurrencySymbol} from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import {AuthService} from './services/auth.service';

@Pipe({
  name: 'space'
})
export class SpacePipe implements PipeTransform {

  constructor(private authService: AuthService) {}

  transform(value: any, ...args: unknown[]): unknown {
    if(!value || value.length < 1) return value;
    const userData = this.authService.getUserLocal();
    const currency:any = args[0] || userData.businessDetails.currency;
    if(!currency) return value;
    const currencySymbol = getCurrencySymbol(currency, 'wide');
    return value.replace(currencySymbol, currencySymbol + ' ');
  }

}
