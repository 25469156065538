import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {BehaviorSubject, Observable} from 'rxjs';
import { InvoiceStatus } from 'src/app/model/invoice';
import { ConfirmModalComponent } from 'src/app/shared/dialog/confirm-modal/confirm-modal.component';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
 
  status:any = InvoiceStatus;
  //store invoice
  private _invoiceDataService$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public getInvoiceData$:Observable<any> = this._invoiceDataService$.asObservable();
  public _setWallectStatus$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(
    public http: HttpClient,
    public dialog: MatDialog, 
    private snackBar: MatSnackBar) {

  }

  //update invoice
  updateInvoiceData$(invoice: any) {
    this._invoiceDataService$.next(invoice);
  }

  updateWalletStatus(invoice: any) {
    this._setWallectStatus$.next(invoice);
  }

/************* dashboard  **********/
exportInvoiceTableToCsv(): Observable<any> {
  const url = environment.url + 'core/invoice/downloadCsv';
  let options = { headers: new HttpHeaders({
    Accept: 'text/csv',
  }), responseType: 'text' as any }
  return this.http.get(url, options);
}

  
/**************** Invoice **********************/
  getInvoiceListAsync(): Observable<any> {
    const url = environment.url + 'core/invoice/invoiceList';
    return this.http.get(url);
  }

  invoiceListByFilter(filter:any): Observable<any> {
    const url = environment.url + 'core/invoice/invoiceList';
    return this.http.post(url, {filter} );
  }

  getInvoice(id: string): Observable<any> {
    const url = environment.url + 'core/invoice/'+id;
    return this.http.get(url);
  }

  createInvoice(invoice: any): Observable<any> {
    const url = environment.url + 'core/invoice';
    return this.http.post(url, { invoice });
  }

  updateInvoice(id: string, invoice: any): Observable<any> {
    const url = environment.url + 'core/invoice/'+id;
    return this.http.put(url, { invoice });
  }

  getInvoiceDashboard():Observable<any> {
    const url = environment.url + 'core/invoice/dashboard';
    return this.http.get(url);
  }

  sendInvoiceRemainder(invoiceID: any): Observable<any> {
    const url = environment.url + 'core/invoice/'+invoiceID+'/reminder';
    return this.http.post(url, {});
  }

  resendInvoice(invoiceID: any): Observable<any> {
    const url = environment.url + 'core/invoice/'+invoiceID+'/resend';
    return this.http.post(url, {});
  }

  /******************* Customer ***********************/
  getCustomerListAsync(customerName?:any): Observable<any> {
    let query = '';
    if(customerName) query = '?customerName='+customerName;
    const url = environment.url + 'core/customer/customerList' + query;
    return this.http.get(url);
  }

  getCustomersByEmail(email:any): Observable<any> {
    const url = environment.url + 'core/customer'+email;
    return this.http.get(url);
  }

  addCustomer(customer: any): Observable<any> {
    const url = environment.url + 'core/customer';
    return this.http.post(url, { customer });
  }

  updateCustomerByEmail(email: string, customer: any): Observable<any> {
    const url = environment.url + 'core/customer/'+email;
    return this.http.put(url, { customer });
  }

  deleteCustomerByEmail(email: string): Observable<any> {
    const url = environment.url + 'core/customer/'+email;
    return this.http.delete(url);
  }

/**************** line Item ***************************/
  getItemList(productName?:string): Observable<any> {
    let query = '';
    if(productName) query = '?productName=' + productName;
    const url = environment.url + 'core/merchant/itemList' + query;
    return this.http.get(url);
  }

  createNewItem(item: any): Observable<any> {
    const url = environment.url + 'core/merchant/item';
    return this.http.post(url, { item });
  }

  updateItem(item: any): Observable<any> {
    const url = environment.url + 'core/merchant/item';
    return this.http.put(url, { item });
  }

  deleteItem(id: any): Observable<any> {
    const url = environment.url + 'core/merchant/item?id=' + id;
    return this.http.delete(url);
  }

  getSettledTransactionListAsync(filter:any): Observable<any> {
    const url = environment.url + 'core/invoice/getSettledTransactionList';
    return this.http.post(url, {filter});
  }

  /** payment link */
  createPaymentLink(link: any): Observable<any> {
    const url = environment.url + 'core/invoice/paymentLink';
    return this.http.post(url, { link });
  }

  paymentLinkList(): Observable<any> {
    const url = environment.url + 'core/invoice/paymentLink/list';
    return this.http.get(url);
  }

  calculationTax = (type: any, amount:any, taxNumber:any) => {
    switch (type) {
      case '%':
        return this.percentage(amount, taxNumber);
        break;
      case '#':
        return taxNumber;
        break;
      default :
        return taxNumber
        break;
    }
  };

  percentage = (amount:any, percent:any) => {
    return ((parseFloat(amount) * parseFloat(percent)) / 100).toFixed(2);
  } 

  calGridAmount(items: any[], element?:any) {
    return items.reduce((total, current) => 
    !element.subscriptionId ?
    total + (this.calculateAmount(current)) : total + current.amount, 
    0)
  }

  calculateAmount(item: any) {
    let price:any = item.unitPrice * item.quantity;
    if(item.tax && item.taxType) price = (parseFloat(this.calculationTax(item.taxType, price, item.tax)) + parseFloat(price)).toFixed(2);
    return price;
  }

  calculateDiscount = (item:any) => {
    return item.reduce(
      (total: any, current: any) =>
      parseFloat(total) + parseFloat(current.discount),
      0
    );
  }

  calTotalAmount(items:any) {
    let totalAmount:any = 0;
    let totalTaxAmount:any = 0;
    let totalWithoutTax:any= 0;
    items.forEach((e: any, i: any) => {
      totalAmount += +e.amount;
      totalAmount.toFixed(2);
      if (e.unitPrice && e.taxType && e.tax && e.quantity) {
        totalTaxAmount = (parseFloat(this.calculationTax(e.taxType, parseFloat(e.quantity) * parseFloat(e.unitPrice), e.tax)) + parseFloat(totalTaxAmount)).toFixed(2);
      }
    });
    totalWithoutTax = totalAmount - totalTaxAmount;
    return {totalAmount, totalTaxAmount, totalWithoutTax}
  }

  sendPaymentReminder(invoice:any) {
    if(this.status[invoice.invoiceStatus] > 2 && this.status[invoice.invoiceStatus] < 5) {
      this.snackBar.open('Payment already done!', 'Close', { duration: 5000, panelClass:['snack-error'] });
      return;
    }
    const data = {
      heading: 'Send Payment Reminder',
      subheading:
      `A payment reminder will be sent to <b>${invoice?.billTo?.email}</b> for <b>Invoice#-${invoice.invoiceID} </b>issued to <b> ${invoice.billTo.name}</b>.`,
      cancelBtn: 'Cancel',
      okBtnLink: null,
      okBtn: 'Confirm',
    };
    this.dialog.open(ConfirmModalComponent, {
      data,
    }).afterClosed().subscribe(res => {
      if(res){
        this.sendInvoiceRemainder(invoice.refID).subscribe(res => {
          this.snackBar.open('Sent Payment Reminder', 'Close', { duration: 5000, panelClass:['snack-sucess'] });
        }, err => {
          this.snackBar.open('Payment Reminder send Failed!', 'Close', { duration: 5000, panelClass:['snack-error'] });
        })

      }
    });
  }

  resendInvoiceToCustomer(invoice:any){
    if(this.status[invoice.invoiceStatus] > 2 && this.status[invoice.invoiceStatus] < 5) {
      this.snackBar.open('Payment already done!', 'Close', { duration: 5000, panelClass:['snack-error'] });
      return;
    }
    const data = {
      heading: 'Resend Invoice',
      subheading:
      `Invoice <b>Invoice#-${invoice.invoiceID}</b> issued to <b>${invoice.billTo.name}</b> will be sent to <b>${invoice.billTo.email}</b>`,
      cancelBtn: 'Cancel',
      okBtnLink: null,
      okBtn: 'Confirm',
    };
    this.dialog.open(ConfirmModalComponent, {
      data,
    }).afterClosed().subscribe(res => {
      if(res){
        this.resendInvoice(invoice.refID).subscribe(res => {
          this.snackBar.open('Resent Invoice !', 'Close', { duration: 5000, panelClass: ['snack-sucess'] });
        }, err => {
          this.snackBar.open(' Resend Invoice Failed!', 'Close', { duration: 5000, panelClass:['snack-error'] });
        });
      }
    });
  }

  archiveInvoice (invoice:any, cb:any){
    if(this.status[invoice.invoiceStatus] > 5) {
      this.snackBar.open('Invoice already archived!', 'Close', { duration: 5000, panelClass:['snack-error'] });
      return;
    }
    this.dialog.open(ConfirmModalComponent, {
      data: {
        heading: 'Archive Invoice',
        subheading:
          `Archiving doen’t fully delete this invoice. You can still view the invoice under archive section . Are are sure you want to proceed`,
          cancelBtn: 'No',
        okBtnLink: null,
        okBtn: 'Yes',
      },
    }).afterClosed().subscribe(res => {
      if(res){
        invoice['invoiceStatus'] = 'ARCHIVED';
        this.updateInvoice(invoice.refID, invoice).subscribe(res => {
          if(cb) cb();
          this.snackBar.open('Archived Invoice !', 'Close', { duration: 5000, panelClass: ['snack-sucess'] });
        }, err => {
          this.snackBar.open(' Archived Invoice Failed!', 'Close', { duration: 5000, panelClass:['snack-error'] });
        })
      }
    });
  }
}
